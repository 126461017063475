(function () {


  let $todoList = document.querySelector('.todo-list')
  if ($todoList) {
    if (window.innerWidth > 992) {
      let feeds = $('.todo');
      for (let i = 0; i < feeds.length; i += 2) {
        feeds.slice(i, i + 2).wrapAll("<div class='slide'></div>");
      }
    }
    $('.todo-list')
      .slick({
        mobileFirst: true,
        slidesToShow: 1,
        dots: true,
        arrows: false,
        slidesToScroll: 1,
        infinite: true,
        responsive: [
          {
            breakpoint: 1365,
            settings: {
              dots: false,
              arrows: true,
            }
          }
        ]
      });
  }



  function debounce(func, wait, immediate) {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }
})()
