(function () {
  let instafeed = document.querySelector('#instafeed')
  if (instafeed) {
    let feeds = $('#instafeed a');
    for( let i = 0; i < feeds.length; i+=2) {
      feeds.slice(i, i+2).wrapAll("<div class='slide'></div>");
    }
    $('#instafeed').slick({
        dots: false,
        arrows: false,
        slidesToScroll: 1,
        slidesToShow: 1,
        variableWidth: true,
        infinite: true,
        mobileFirst: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              centerMode: true
            }
          }
        ]
      });
  }
})()