document.addEventListener("DOMContentLoaded", function() {  // Don't touch
  // Don't touch
  objectFitImages();
  svg4everybody();
  // End don't touch

  require('./target-blank.js');
  require('./menu')
  require('./header')
  require('./todo')
  // require('./weather')
  require('./animations')
  require('./instafeed')
  require('./booking')
  require('./hotel')
  require('./sliders')
  require('./post')
  require('./form')
  require('./cutted-text')
  require('./map')
  require('./ttd-filter')
  require('./loadmore-trip-ideas')
  require('./loadmore-events')
  require('./loadmore-search-results')
  require('./loadmore-hotels')
  require('./video')
});

// remove preloader
window.addEventListener('load', function () {
  // $('.preloader').fadeOut();
  document.querySelector('html').classList.add('page-loaded');
  AOS.init({
    once: true,
    useClassNames: true,
    duration: 500,
    delay: 200,
    // easing: 'linear',
    // easing: 'ease',
    // easing: 'ease-in',
    // easing: 'ease-out',
    // easing: 'ease-in-out',
    // easing: 'ease-in-back',
    // easing: 'ease-out-back',
    // easing: 'ease-in-out-back',
    // easing: 'ease-in-sine',
    // easing: 'ease-out-sine',
    // easing: 'ease-in-out-sine',
    // easing: 'ease-in-quad',
    // easing: 'ease-out-quad',
    // easing: 'ease-in-out-quad',
    // easing: 'ease-in-cubic',
    // easing: 'ease-out-cubic',
    // easing: 'ease-in-out-cubic',
    // easing: 'ease-in-quart',
    // easing: 'ease-out-quart',
    easing: 'ease-in-out-quart',

  });
});
