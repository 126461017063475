(function () {
  $('#booking-from').daterangepicker({
    minDate: new Date(),
    autoUpdateInput: false,
    locale: {
      format: 'MMM, D ddd',
      monthNames : [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      "applyLabel": "Confirm"
    }
  });

  $('#booking-to').on('click', function () {
    $('#booking-from').trigger('click')
  });

  $('.booking__icon').on('click', function () {
    $('#booking-from').trigger('click')
  });
  $('#booking-from').on('show.daterangepicker', function(ev, picker) {
    $('body').addClass('datepicker-open')
  });

  $('#booking-from').on('hide.daterangepicker', function(ev, picker) {
    $('#booking-from').val(picker.startDate.format('MMM D'));
    $('#booking-to').val(picker.endDate.format('MMM D'));
    $('body').removeClass('datepicker-open')
  });

})()
