(function () {
  if ( document.querySelector('#ttd-archive') ) {
    let currCategoty, currSubCategoty = null
    let category      = document.querySelector('#filter-category')
    let subCategories = document.querySelector('.categories')
    let serachList    = document.querySelector('.search-results-list')
    let ajaxAlert     = document.querySelector('.ajax') 
    let loadmore      = document.querySelector('#loadmore')
    let currPage      = 1

    category.addEventListener('change', (e) => {
      e.preventDefault()
      currPage     = 1
      currCategoty = e.target.value
      subCategories.innerHTML = ''

      getActivities( currCategoty, currSubCategoty, currPage )
    })

    loadmore.addEventListener('click', (e) => {
      e.preventDefault()

      getActivities( currCategoty, currSubCategoty, ++currPage )
    })

    let updateInfo = ( count, parent, child ) => {
      let serachInfo       = document.querySelector('.search-results_info')
      let serachInfoCount  = serachInfo.querySelector('.search-results-count')
      let serachInfoParent = serachInfo.querySelector('.search-category')
      let serachInfoChild  = serachInfo.querySelector('.search-subcategory')

      serachInfo.style.display   = 'block'
      serachInfoCount.innerHTML  = count
      serachInfoParent.innerHTML = parent.name

      if ( child ) {
        serachInfoChild.innerHTML = ' - ' + child.name
      }
    }

    let renderSubCategories = ( children ) => {
      children.forEach( el => {
        let item  = document.createElement('div')
        let block = document.createElement('div')
        let img_w = document.createElement('div')
        let img   = document.createElement('img')
        let label = document.createElement('label')
        let input = document.createElement('input')
        let span  = document.createElement('span')

        item.classList.add('categories__item')
        label.classList.add('category')
        block.classList.add('category__block')
        span.classList.add('category__title')

        img.setAttribute('src', el.image)
        input.setAttribute('type', 'radio')
        input.setAttribute('name', 'category')
        input.setAttribute('value', el.term_id)

        span.innerHTML = el.name

        img_w.appendChild(img)
        block.appendChild(img_w)
        block.appendChild(span)
        label.appendChild(block)
        label.appendChild(input)
        item.appendChild(label)
        subCategories.appendChild(item)

        input.addEventListener('change', (e) => {
          currPage        = 1
          currSubCategoty = e.target.value

          getActivities(currCategoty, currSubCategoty, currPage)
        })
      } )
    }

    let getActivities = ( parent, child, page ) => {
      $.ajax({
        url: _wpObject.ajaxUrl,
        dataType: "json",
        type: "POST",
        data: {
          "page"  : page,
          "child" : child,
          "parent": parent,
          "action": "get_activities"
        },
        beforeSend: () => {
          ajaxAlert.innerHTML = 'Loading...'
        },
        success: function(response) {
          ajaxAlert.innerHTML = ''
          
          if ( page == 1 ) {
            updateInfo( response.count, response.parent, response.child )
            
            if ( child == null  && response.children ) {
              renderSubCategories( response.children )
            }

            serachList.innerHTML = response.posts
          } else {
            serachList.innerHTML += response.posts
          }

          if ( response.loadmore == false ) {
            loadmore.style.visibility = 'hidden'
          } else {
            loadmore.style.visibility = 'visible'
          }
        }
      });
    }
  }
})()
