(function () {

  const $header = document.querySelector('.header');
  // const $menu = document.querySelector('.main-nav');
  // const $hamburger = document.querySelector('.burger');
  // let isMenuOpened = false;
  // $hamburger.addEventListener('click', function () {
  //   this.classList.toggle('opened')
  //   $menu.classList.toggle('opened');
  //   $header.classList.toggle('menu-opened');
  //   isMenuOpened = !isMenuOpened;
  //
  //   if (isMenuOpened) {
  //     bodyScrollLock.disableBodyScroll($menu);
  //   } else {
  //     bodyScrollLock.clearAllBodyScrollLocks();
  //   }
  // });
  //
  // window.addEventListener('resize', debounce(function () {
  //   if (isMenuOpened && window.innerWidth >= 768) {
  //     bodyScrollLock.clearAllBodyScrollLocks();
  //   }
  // }))


  const $search = document.querySelector('.header .search');
  const $socialOpener = document.querySelector('.header__socials span');
  const $socials = document.querySelector('.header__socials');
  const $searchButton = document.querySelector('.search__button')
  const $headerSearch = document.querySelector('.form--header-search')

  $headerSearch.addEventListener('submit', function (e) {
    e.stopPropagation()
  })

  $searchButton.addEventListener('click', function (e) {
    if (!$search.classList.contains('opened')) {
      e.preventDefault()
      $search.classList.add('opened');
      $header.classList.add('search-opened')
    }
  })

  $socialOpener.addEventListener('click', function () {
    $socials.classList.toggle('opened')
  })

  document.querySelector('.search__close').addEventListener('click', function (e) {
    console.log('click')
    e.preventDefault()
    $header.classList.remove('search-opened')
    $search.classList.remove('opened');
  })

  function debounce(func, wait, immediate) {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }
})();
