(function () {

  const controller = new ScrollMagic.Controller();

  $('.typed-block').each(function () {
    let currentEle = this;
    let typed = this.querySelector('.typed');
    let typedString = this.querySelector('.typed-strings')
    new Typed( typed, {
      stringsElement: typedString,
      typeSpeed: 50,
      backSpeed: 20,
      loop: false,
      fadeOut: true,
      showCursor: false
    });
  })


  $('.accent-title').each(function () {
    let currentEle = this;
    let typed = this.querySelector('.typed');
    let typedString = this.querySelector('.typed-strings')

    new ScrollMagic.Scene({
      triggerElement: currentEle,
      triggerHook: 0.7,
      reverse: false
    })
      .on('start', function () {
        new Typed( typed, {
          stringsElement: typedString,
          typeSpeed: 50,
          backSpeed: 20,
          loop: false,
          fadeOut: true,
          showCursor: false
        });
      })
      .addTo(controller);
  });
})()