(function (){
   const policy = document.querySelectorAll('.policy-block')

   policy.forEach(block => {
       const text = block.querySelector('.policy-block__hidden')
       const button = block.querySelector('.cta')

       if (text) {
           button.addEventListener('click', function (e) {
               e.preventDefault()
               text.classList.toggle('visible')
               if (text.classList.contains('visible')) {
                   button.textContent = 'Show less'
               } else {
                   button.textContent = 'Show more'
               }
           })
       }
   })
})()