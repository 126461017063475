(function () {
   const videoBlocks = document.querySelectorAll('.video-wrapper')

   videoBlocks.forEach(block => {
       const poster = block.querySelector('.video-wrapper__poster')
       const video = block.getElementsByTagName('iframe')[0]
       poster.addEventListener('click', function () {
           this.classList.add('hidden')
            video.src+="&autoplay=1"
       })
   })
})()