(function () {
    const googleMapEle = document.getElementById('map');

    if (googleMapEle) {

        const address = googleMapEle.getAttribute('data-address')
        const name = googleMapEle.getAttribute('data-name')

        let popupEle

        if (name) {
            popupEle = document.createElement('div')
            popupEle.className = 'map-popup'
            popupEle.textContent = name
            googleMapEle.after(popupEle)
        }

        function initMap() {

            const geocoder = new google.maps.Geocoder();
            const map = new google.maps.Map(googleMapEle, {
                zoom: 15,
                center: { lat: -25.344, lng: 131.031 },
                disableDefaultUI: true
            })

            class Popup extends google.maps.OverlayView {

                constructor(position, content) {
                    super();
                    this.position = position;

                    content.classList.add("popup-bubble");

                    // This zero-height div is positioned at the bottom of the bubble.
                    const bubbleAnchor = document.createElement("div");

                    bubbleAnchor.classList.add("popup-bubble-anchor");
                    bubbleAnchor.appendChild(content);

                    // This zero-height div is positioned at the bottom of the tip.
                    this.containerDiv = document.createElement("div");
                    this.containerDiv.classList.add("popup-container");
                    this.containerDiv.appendChild(bubbleAnchor);

                    // Optionally stop clicks, etc., from bubbling up to the map.
                    Popup.preventMapHitsAndGesturesFrom(this.containerDiv);
                }

                /** Called when the popup is added to the map. */
                onAdd() {
                    this.getPanes().floatPane.appendChild(this.containerDiv);
                }

                /** Called when the popup is removed from the map. */
                onRemove() {
                    if (this.containerDiv.parentElement) {
                        this.containerDiv.parentElement.removeChild(this.containerDiv);
                    }
                }

                /** Called each frame when the popup needs to draw itself. */
                draw() {
                    const divPosition = this.getProjection().fromLatLngToDivPixel(
                        this.position
                    );

                    // Hide the popup when it is far out of view.
                    const display =
                        Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
                            ? "block"
                            : "none";

                    if (display === "block") {
                        this.containerDiv.style.left = divPosition.x + "px";
                        this.containerDiv.style.top = divPosition.y + "px";
                    }

                    if (this.containerDiv.style.display !== display) {
                        this.containerDiv.style.display = display;
                    }
                }
            }

            geocoder.geocode( { 'address': address}, function(results, status) {

                if (status == 'OK') {
                    map.setCenter(results[0].geometry.location);

                    if (name) {
                        let popup = new Popup(
                            new google.maps.LatLng(results[0].geometry.location),
                            popupEle
                        );
                        popup.setMap(map);
                    }

                } else {
                    alert('Geocode was not successful for the following reason: ' + status);
                }
            });
        }


        initMap()

    }
})()