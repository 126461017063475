(function () {

    const prevArrow = `
    <button type="button" class="slick-arrow__prev">
     <svg width="35" height="65" viewBox="0 0 35 65" xmlns="http://www.w3.org/2000/svg">
        <path d="M33 2.11 2.872 32.236 33 62.364" stroke="#EECA3A" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round"/>
    </svg>
    </button>
  `;

    const nextArrow = `
    <button type="button" class="slick-arrow__next">
       <svg width="35" height="65" viewBox="0 0 35 65" xmlns="http://www.w3.org/2000/svg">
        <path d="m2 2.11 30.128 30.127L2 62.364" stroke="#EECA3A" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round"/>
      </svg>
    </button>
  `;

    $('.hotel-todo-slider')
        .on('destroy', function (ev, slick) {
            let carousel = $(this),
                reinit = debounce(function () {
                    carousel.slick(slick.options);
                    window.removeEventListener('resize', reinit);
                }, 500);

            // Assign our debounced callback to window.resize.
            window.addEventListener('resize', reinit);
        })
        .slick({
            mobileFirst: true,
            slidesToShow: 1,
            dots: true,
            arrows: false,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 768,
                    settings: 'unslick'
                }
            ]
        });

    $('.hotel-event-slider')
        .on('destroy', function (ev, slick) {
            let carousel = $(this),
                reinit = debounce(function () {
                    carousel.slick(slick.options);
                    window.removeEventListener('resize', reinit);
                }, 500);

            // Assign our debounced callback to window.resize.
            window.addEventListener('resize', reinit);
        })
        .slick({
            mobileFirst: true,
            slidesToShow: 1,
            dots: true,
            arrows: false,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 768,
                    settings: 'unslick'
                }
            ]
        });

    $('.post-gallery-slider').slick({
        dots: false,
        prevArrow,
        nextArrow,
        slidesToScroll: 1,
        slidesToShow: 1,
        infinite: true
    });

    $('.todo-list--without-slider')
        .on('destroy', function (ev, slick) {
            let carousel = $(this),
                reinit = debounce(function () {
                    carousel.slick(slick.options);
                    window.removeEventListener('resize', reinit);
                }, 500);

            // Assign our debounced callback to window.resize.
            window.addEventListener('resize', reinit);
        })
        .slick({
            mobileFirst: true,
            slidesToShow: 1,
            dots: true,
            customPaging: function(slider, i) {
                return `<button class="tab">${i < 10 ? '0' : ''}${i+1}</button>`
            },
            arrows: false,
            slidesToScroll: 1,
            infinite: false,
            responsive: [
                {
                    breakpoint: 768,
                    settings: 'unslick'
                }
            ]
        });

    $('.card-image-round-slider, .featured-slider, .media-slider').slick({
        dots: false,
        prevArrow,
        nextArrow,
        slidesToScroll: 1,
        slidesToShow: 1,
        infinite: true
    })


    $('.getting-list')
        .on('destroy', function (ev, slick) {
            let carousel = $(this),
                reinit = debounce(function () {
                    carousel.slick(slick.options);
                    window.removeEventListener('resize', reinit);
                }, 500);

            // Assign our debounced callback to window.resize.
            window.addEventListener('resize', reinit);
        })
        .slick({
            mobileFirst: true,
            slidesToShow: 1,
            dots: true,
            customPaging: function(slider, i) {
                return `<button class="tab">${i < 10 ? '0' : ''}${i+1}</button>`
            },
            arrows: false,
            slidesToScroll: 1,
            adaptiveHeight: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: 'unslick'
                }
            ]
        });

    function debounce(func, wait, immediate) {
        var timeout;
        return function () {
            var context = this,
                args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }
})()