(function () {
    let hotelGallery = document.querySelector('.hotel-gallery')
    if (hotelGallery) {
        let image = $('.hotel-gallery__item');
        for( let i = 0; i < image.length; i+=2) {
            image.slice(i, i+2).wrapAll("<div class='slide'></div>");
        }
        $('.hotel-gallery').slick({
            dots: false,
            arrows: false,
            slidesToScroll: 1,
            slidesToShow: 1,
            variableWidth: true,
            infinite: true,
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        centerMode: true
                    }
                }
            ]
        });
    }


})()