(function (){
    const googleMapEle = document.getElementById('post-map');

    if (googleMapEle) {
        const markers = document.querySelectorAll('.map--post .map__marker')

        function initMap() {
            const geocoder = new google.maps.Geocoder();
            const map = new google.maps.Map(googleMapEle, {
                zoom: 15,
                center: { lat: -25.344, lng: 131.031 },
                disableDefaultUI: true
            })
            markers.forEach((item, index) => {
                const address = item.getAttribute('data-address')
                let lat = 0;
                let lng = 0;
                let color = ''
                switch (index) {
                    case 1:
                        color = '#69b200';
                        break
                    case 2:
                        color = '#abd0e9';
                        break;
                    case 3:
                        color = '#007bc3';
                        break;
                    case 4:
                        color = '#06314d';
                        break;
                    case 5:
                        color = '#f58220';
                        break
                    case 6:
                        color = '#7f7f7f';
                        break;
                    default:
                        color = '#ffc90e';
                        break;
                }
                geocoder.geocode( { 'address': address}, function(results, status) {
                    if (status == 'OK') {
                        lat = lat + results[0].geometry.location.lat()
                        lng = lng + results[0].geometry.location.lng()
                        if (index === 0) {
                            map.setCenter({lat, lng});
                        }
                        var marker = new google.maps.Marker({
                            map: map,
                            position: results[0].geometry.location,
                            icon: {
                                path: 'M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z',
                                fillColor: color,
                                fillOpacity: 1,
                                scale: 0.075,
                                labelOrigin: new google.maps.Point(255, 255)
                            },
                            label: {
                                text: `0${index + 1}`,
                                color: '#fff',
                                fontSize: '26px',
                                fontFamily: 'rift,sans-serif',
                                fontWeight: '600'
                            }
                        });
                    } else {
                        alert('Geocode was not successful for the following reason: ' + status);
                    }
                });
            })
        }

        initMap()

    }
})()