(function () {
    if ( document.querySelector('.events') ) {
        let currPage = 1;
        $('#load-more').on('click', function() {
            currPage++; // Load next page    
            $.ajax({
                type: 'POST',
                url: _wpObject.ajaxUrl,
                dataType: 'json',
                data: {
                    action: 'loadmore_events',
                    paged: currPage
                },
                success: function (res) {
                    if (currPage >= res.max) {
                        $('#load-more').hide();
                    }
                    $('.event-list').append(res.html);
                }
            })
        })
    }
})()